import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { useHttp } from '../../hooks/http.hook';

import Loading from '../../components/Loading';

const TermsPage = () => {
  const { appName } = useParams();
  const { loading, request, clearError } = useHttp();
  const [termsText, setTermsText] = useState('');
  const [appFullName, setAppFullName] = useState('');
  const [lastUpdate, setLastUpdate] = useState('');

  useEffect(() => {
    downloadDocument();
  }, []);

  const downloadDocument = async () => {
    clearError();
    try {
      const response = await request(`documents/terms/${appName}`, 'GET', null);
      const { appFullName, lastUpdate, terms } = response;
      setTermsText(terms);
      setAppFullName(appFullName);
      setLastUpdate(lastUpdate);
    } catch (error) {}
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Welcome to app support form!" />
        <title>{appFullName} | Terms</title>
      </Helmet>
      <Box padding={2}>
        <Typography variant="h4" gutterBottom>
          {`${appFullName} Terms & Conditions`}
        </Typography>
        <ReactMarkdown>{termsText}</ReactMarkdown>
        <Typography variant="body2" color="textSecondary">
          Last updated: {lastUpdate}
        </Typography>
      </Box>
    </>
  );
};

export default TermsPage;
