import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, List, ListItem, Link, Button, ListItemText } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useHttp } from '../../hooks/http.hook';

import { ReactComponent as AppleIcon } from '../../assets/Apple.svg';
import Loading from '../../components/Loading';

const MarketingPage = () => {
  const { appName } = useParams();
  const { loading, request, clearError } = useHttp();
  const [appFullName, setAppFullName] = useState('');
  const [appIconPath, setAppIconPath] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [utp, setUtp] = useState([]);
  const [appstoreUrl, setAppstoreUrl] = useState('');
  const [mainImage, setMainImage] = useState('');
  const [appInternalName, setAppInternalName] = useState('');
  const [appError, setAppError] = useState(false);

  useEffect(() => {
    loadAppData();
  }, []);

  const loadAppData = async () => {
    clearError();
    try {
      const response = await request(`applications/${appName}`, 'GET', null);
      const { appFullName, appIconPath, utp, bgImage, title, description, appstoreUrl, appInternalName } = response;

      setAppInternalName(appInternalName);
      setAppFullName(appFullName);
      setAppIconPath(appIconPath);
      setTitle(title);
      setDescription(description);
      setUtp(utp);
      setMainImage(bgImage);
      setAppstoreUrl(appstoreUrl);
    } catch (e) {
      setAppError(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: { md: '100vh' },
        minHeight: { xs: '100vh' },
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing: 'border-box',
        overflow: 'hidden',
        position: 'relative',
        '::before': {
          content: '""',
          position: 'absolute',
          bottom: -70,
          left: -120,
          width: { xs: '368px', md: '568px' },
          height: { xs: '368px', md: '509px' },
          background: 'radial-gradient(50% 50% at 50% 50%, rgba(147, 123, 242, 0.4) 0%, rgba(21, 119, 141, 0) 100%)',
          zIndex: -1,
        },
        '::after': {
          content: '""',
          position: 'absolute',
          top: -100,
          right: -100,
          width: { xs: '368px', md: '660px' },
          height: { xs: '368px', md: '660px' },
          background: 'radial-gradient(50% 50% at 50% 50%, rgba(57, 139, 255, 0.6) 0%, rgba(21, 119, 141, 0) 100%)',
          zIndex: -1,
        },
      }}
    >
      {loading ? (
        <Loading />
      ) : appError ? (
        <AppNotFound />
      ) : (
        <>
          <Helmet>
            <meta name="description" content="Welcome to app information page!" />
            <title>{appFullName} | Information page</title>
          </Helmet>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              width: '100%',
              maxWidth: '1200px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'center', md: 'flex-start' },
                textAlign: { xs: 'center', md: 'left' },
                flexBasis: { xs: '100%', md: '50%' },
                padding: { xs: '76px 16px 45px', md: '20px 20px 20px 40px' },
              }}
            >
              <AppIcon iconURL={appIconPath} />
              <Typography sx={{ fontWeight: 500, fontSize: { xs: 32, md: 32 } }}>{appFullName}</Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: 42, md: 64 },
                  lineHeight: { xs: '50px', md: '76px' },
                }}
              >
                {title}
              </Typography>

              <List
                sx={{
                  fontWeight: 400,
                  fontSize: 20,
                  padding: '24px 0',
                }}
              >
                {utp.map((item, index) => (
                  <ListItem key={index} sx={{ padding: 0, fontSize: { xs: '16px', md: '20px' } }}>
                    <div
                      style={{
                        border: '4px solid black',
                        backgroundColor: 'black',
                        borderRadius: 20,
                        marginRight: 5,
                      }}
                    ></div>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>

              <Box
                sx={{
                  marginBottom: { xs: '24px', md: '32px' },
                  height: { xs: '100%', md: '150px' },
                  overflowY: 'auto',
                }}
              >
                <Typography variant="body2">{description}</Typography>
              </Box>
              <DownloadAppStoreButton href={appstoreUrl} />
            </Box>

            <Box
              sx={{
                flexBasis: { xs: '0%', md: '40%' },
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
              }}
            >
              <Box
                component="img"
                src={mainImage}
                alt="Example"
                sx={{
                  width: '100%',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
              padding: '10px 0',
            }}
          >
            <Link href={`/${appInternalName}/privacy`} underline="none" sx={{ color: 'black' }}>
              Privacy Policy
            </Link>
            <Link href={`/${appInternalName}/terms`} underline="none" sx={{ color: 'black' }}>
              Terms of Use
            </Link>
          </Box>
        </>
      )}
    </Box>
  );
};

const AppIcon = ({ iconURL }: any) => (
  <Box sx={{ maxWidth: 48, maxHeight: 48, mr: 2 }}>
    <img
      src={iconURL}
      alt="app-icon"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '5px',
      }}
    />
  </Box>
);

const AppNotFound = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
    }}
  >
    <Typography variant="h1" color="error.main">
      404
    </Typography>
    <Typography variant="h5">Application Not Found</Typography>
    <Typography variant="subtitle1" textAlign="center" color="text.secondary">
      Sorry, we couldn't find the application you were looking for. Please check the URL and try again.
    </Typography>
  </Box>
);
const DownloadAppStoreButton = ({ href }: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '168px',
        height: '57px',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#000',
          '&:hover': {
            backgroundColor: '#333',
          },
          textTransform: 'none',
          padding: '12px 32px 12px 16px',
          border: '1px solid #A6A6A6',
          borderRadius: '8px',
        }}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <AppleIcon style={{ marginRight: '8px' }} />
        <Box>
          <Typography sx={{ fontSize: '10px', color: '#fff' }}>Download on the</Typography>
          <Typography
            sx={{
              fontSize: '16px',
              color: '#fff',
              fontWeight: 'bold',
              lineHeight: '18px',
            }}
          >
            App Store
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};

export default MarketingPage;
