// mui theme settings
export const themeSettings = () => {
  return {
    palette: {
      primary: {
        main: '#0066CC',
        light: '#42B72A',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#607D8B',
        contrastText: '#FFFFFF',
      },
      background: {
        paper: '#F0F2F5',
        default: '#FFFFFF',
      },
    },
  };
};
