import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

const SuccessPage = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content="Submission successful" />
        <title>Submission Successful</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1" color="success.main">
          Success!
        </Typography>
        <Typography variant="h4" mb={3}>
          Your submission was successful.
        </Typography>
        <Typography variant="subtitle1" mb={3}>
          Thank you for your submission. Our team will get back to you soon.
        </Typography>
      </Box>
    </>
  );
};

export default SuccessPage;
