import { useState, useCallback } from 'react';

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const request = useCallback(async (url: string, method = 'GET', body: any) => {
    setLoading(true);

    url = process.env.REACT_APP_BACKEND_URL + url;

    try {
      setError(null);

      const response = await fetch(url, { method, body });

      if (!response.ok) {
        throw new Error(`Could not fetch ${url}, status: ${response.status}`);
      }

      const data = await response.json();

      setLoading(false);
      return data;
    } catch (e) {
      setLoading(false);
      if (e instanceof Error) {
        setError(e.message);
        throw e;
      } else {
        throw new Error('An unexpected error occurred.');
      }
    }
  }, []);

  const clearError = useCallback(() => setError(null), []);

  return { loading, request, error, clearError };
};
