import { Box, Typography, Paper } from '@mui/material';
import { Helmet } from 'react-helmet';
import ConstructionIcon from '@mui/icons-material/Construction';

const MainPage = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content="Welcome to app main page!" />
        <title>Main page</title>
      </Helmet>
      <Box>
        <Paper sx={{ backgroundColor: 'warning.main', color: 'common.white', p: 2, mb: 2, borderRadius: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ConstructionIcon sx={{ fontSize: 50, mb: 1 }} />
            <Typography variant="h5" textAlign="center">
              Note: This page is still under development.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default MainPage;
