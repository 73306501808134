import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useHttp } from '../../hooks/http.hook';

import SupportForm from '../../components/SupportForm';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';

const AppSupportPage = () => {
  const { appName } = useParams();
  const { loading, request, clearError } = useHttp();
  const [appInternalName, setAppInternalName] = useState('');
  const [appFullName, setAppFullName] = useState('');
  const [appIconPath, setAppIconPath] = useState('');
  const [appError, setAppError] = useState(false);

  useEffect(() => {
    loadAppData();
  }, []);

  const loadAppData = async () => {
    clearError();
    try {
      const response = await request(`applications/${appName}`, 'GET', null);
      const { appInternalName, appFullName, appIconPath } = response;
      setAppInternalName(appInternalName);
      setAppFullName(appFullName);
      setAppIconPath(appIconPath);
    } catch (e) {
      setAppError(true);
    }
  };

  return (
    <Box sx={{ height: '100vh' }}>
      {loading ? (
        <Loading />
      ) : appError ? (
        <AppNotFound />
      ) : (
        <>
          <Helmet>
            <meta name="description" content="Welcome to app support form!" />
            <title>{appFullName} | Support Form</title>
          </Helmet>

          <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
            <Container sx={{ paddingTop: 6, paddingBottom: 6 }} maxWidth="sm">
              <Header />

              <Box
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                marginTop={4}
              >
                <AppIcon iconURL={appIconPath} />

                <Typography variant="h2" fontSize={'2rem'} fontWeight={'bold'} marginRight={2}>
                  {appFullName}
                </Typography>
              </Box>

              <Box marginTop={4}>
                <SupportForm appInternalName={appInternalName} />
              </Box>
            </Container>

            {/* <PageFooter /> */}
          </Box>
        </>
      )}
    </Box>
  );
};

const Header = () => (
  <Typography align="center" variant="h1" fontSize={'3rem'} fontWeight={'light'}>
    Support Center
  </Typography>
);

const AppIcon = ({ iconURL }: any) => (
  <Box sx={{ maxWidth: 48, maxHeight: 48, mr: 2 }}>
    <img
      src={iconURL}
      alt="app-icon"
      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px' }}
    />
  </Box>
);

const PageFooter = () => (
  <Box>
    <Footer />
  </Box>
);

const AppNotFound = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
    }}
  >
    <Typography variant="h1" color="error.main">
      404
    </Typography>
    <Typography variant="h5">Application Not Found</Typography>
    <Typography variant="subtitle1" textAlign="center" color="text.secondary">
      Sorry, we couldn't find the application you were looking for. Please check the URL and try again.
    </Typography>
  </Box>
);

export default AppSupportPage;
