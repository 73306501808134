import { Box, Typography, Button } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <>
      <Helmet>
        <meta name="description" content="Page not found" />
        <title>404 Page Not Found</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1" color="error.main">
          404
        </Typography>
        <Typography variant="h4" mb={3}>
          Oops! Page not found.
        </Typography>
        <Typography variant="subtitle1" mb={3}>
          The page you are looking for might have been removed had its name changed or is temporarily unavailable.
        </Typography>
        <Button variant="outlined" onClick={navigateToHome}>
          Go To Homepage
        </Button>
      </Box>
    </>
  );
};

export default NotFoundPage;
