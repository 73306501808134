import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';

// PAGES
import MainPage from './pages/mainPage/MainPage';
import AppSupportPage from './pages/appSupportPage/AppSupportPage';
import NotFoundPage from './pages/notFoundPage/NotFoundPage';
import SuccessPage from './pages/successSupportPage/SuccessSupportPage';
import PrivacyPolicyPage from './pages/privacyPolicyPage/PrivacyPolicyPage';
import TermsPage from './pages/termsPage/TermsPage';
import MarketingPage from './pages/marketingPage/MarketingPage';

function App() {
  const theme = createTheme(themeSettings());

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path=":appName/support" element={<AppSupportPage />} />
            <Route path=":appName/support/success" element={<SuccessPage />} />
            <Route path=":appName/privacy" element={<PrivacyPolicyPage />} />
            <Route path=":appName/terms" element={<TermsPage />} />
            <Route path=":appName/app" element={<MarketingPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
